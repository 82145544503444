
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-role",
  data() {
    return {
      buttonloader: false,
      modalform: {
        name: "",
        desc: ""
      },
      modalformrule: {
        name: {
          required: true,
          message: "Нэр хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async saveRole() {
      if (this.modalform.name.length < 1) return;
      this.buttonloader = true;
      await ApiService.post(`role`, {
        params: {
          rolename: this.modalform.name,
          desc: this.modalform.desc
        }
      })
        .then(resp => {
          this.buttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.$emit("refreshevent");
              this.hidemodalbutton?.$el.click();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error",
              iconClass: "el-text-error"
            });
          }
        })
        .catch(() => {
          this.buttonloader = false;
          Swal.fire({
            text: "Үүрэг нэмэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  },
  setup() {
    const addrolemodalref = ref<InstanceType<typeof ElForm>>();
    const hidemodalbutton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      addrolemodalref.value?.validate(async valid => {
        return !!valid;
      });
    };

    const clearField = () => {
      addrolemodalref.value?.resetFields();
    };

    return {
      addrolemodalref,
      hidemodalbutton,
      clearField,
      formSubmit
    };
  }
});
