
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import AddRole from "@/components/modals/roleAddModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import { ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "UserRole",
  components: { AddRole },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Хэрэглэгчийн эрх");
    });
    const editRoleForm = ref<InstanceType<typeof ElForm>>();
    const editPermissionForm = ref<InstanceType<typeof ElForm>>();
    const editRoleFormSubmit = () => {
      editRoleForm.value?.validate(async valid => {
        return !!valid;
      });
    };
    const editRoleClearField = () => {
      editRoleForm.value?.resetFields();
    };
    const editPermissionFormSubmit = () => {
      editRoleForm.value?.validate(async valid => {
        return !!valid;
      });
    };
    const editPermissionClearField = () => {
      editRoleForm.value?.resetFields();
    };
    return {
      editRoleForm,
      editRoleClearField,
      editRoleFormSubmit,
      editPermissionForm,
      editPermissionClearField,
      editPermissionFormSubmit
    };
  },
  data() {
    return {
      roleName: "",
      roleid: "",
      loadingRolePerTable: false,
      loadingSwitch: false,
      loadingRoleTable: false,
      perListEnabled: false,
      permissioneditmodal: {
        modal: {} as Modal,
        editbuttonloader: false,
        modalform: {
          infotypecode: "",
          infotypename: ""
        },
        modalformrule: {
          infotypename: {
            required: true,
            message: "Нэр хоосон байна.",
            trigger: "blur"
          }
        }
      },
      roleeditmodal: {
        modal: {} as Modal,
        editbuttonloader: false,
        modalform: {
          rolename: "",
          desc: "",
          roleid: ""
        },
        modalformrule: {
          rolename: {
            required: true,
            message: "Нэр хоосон байна.",
            trigger: "blur"
          }
        }
      },
      deletemodal: {
        modal: {} as Modal,
        buttonloader: false,
        rolename: "",
        roleid: ""
      },
      roleTableData: [],
      perTableData: [],
      rolePerTableData: []
    };
  },
  mounted() {
    this.getRoles();
    this.getPermissions();
    this.deletemodal.modal = new Modal(
      this.$refs.deleteRoleModal as HTMLDivElement
    );
    this.roleeditmodal.modal = new Modal(
      this.$refs.editRoleModal as HTMLDivElement
    );
    this.permissioneditmodal.modal = new Modal(
      this.$refs.editPermissionModal as HTMLDivElement
    );
  },
  methods: {
    linkToPermission(event) {
      this.perListEnabled = true;
      this.roleid = event.currentTarget.getAttribute("data-id");
      this.roleName = event.currentTarget.getAttribute("data-name");
      this.getRolePermissions(this.roleid);
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** permission edit modal functions
     ***************************************
     ***************************************
     ***************************************/
    editpermissionhidebutton() {
      this.permissioneditmodal.modal.hide();
    },
    editpermissionshowbutton(row) {
      this.permissioneditmodal.modalform.infotypecode = row.infotypecode;
      this.permissioneditmodal.modalform.infotypename = row.infotypename;
      this.permissioneditmodal.modal.show();
    },
    async editPermission() {
      this.permissioneditmodal.editbuttonloader = true;
      await ApiService.put(`permission`, {
        params: {
          infotypecode: this.permissioneditmodal.modalform.infotypecode,
          infotypename: this.permissioneditmodal.modalform.infotypename
        }
      })
        .then(resp => {
          this.permissioneditmodal.editbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getRolePermissions(this.roleid);
              this.editpermissionhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error",
              iconClass: "el-text-error"
            });
          }
        })
        .catch(() => {
          this.permissioneditmodal.editbuttonloader = false;
          Swal.fire({
            text: "Үүрэг засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** role permission edit functions
     ***************************************
     ***************************************
     ***************************************/
    onChangeSwitch(infotypecode, infostat, trole) {
      this.editRolePermission(this.roleid, infotypecode, infostat, trole);
    },
    async editRolePermission(roleid, typecode, stat, trole) {
      this.loadingSwitch = true;
      const params = {
        roleid: roleid,
        infotypecode: typecode,
        infostat: stat,
        trole: trole
      };
      await ApiService.put(`rolepermission`, { params })
        .then(resp => {
          this.loadingSwitch = false;
          if (resp.data.code === 1) {
            ElNotification({
              title: "Амжилттай",
              message: resp.data.response,
              type: "success",
              iconClass: "el-text-success"
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.getRolePermissions(roleid);
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error",
              iconClass: "el-text-error"
            });
          }
        })
        .catch(() => {
          this.loadingSwitch = false;
          this.getRolePermissions(roleid);
          Swal.fire({
            text: "Эрх засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** role edit modal functions
     ***************************************
     ***************************************
     ***************************************/
    editrolehidebutton() {
      this.roleeditmodal.modal.hide();
    },
    editroleshowbutton(row) {
      this.roleeditmodal.modalform.rolename = row.rolename;
      this.roleeditmodal.modalform.roleid = row.roleid;
      this.roleeditmodal.modalform.desc = row.desc;
      this.roleeditmodal.modal.show();
    },
    async editRole() {
      this.roleeditmodal.editbuttonloader = true;
      await ApiService.put(`role`, {
        params: {
          rolename: this.roleeditmodal.modalform.rolename,
          roleid: this.roleeditmodal.modalform.roleid,
          desc: this.roleeditmodal.modalform.desc
        }
      })
        .then(resp => {
          this.roleeditmodal.editbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getRoles();
              this.editrolehidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error",
              iconClass: "el-text-error"
            });
          }
        })
        .catch(() => {
          this.roleeditmodal.editbuttonloader = false;
          Swal.fire({
            text: "Үүрэг засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** role delete modal functions
     ***************************************
     ***************************************
     ***************************************/
    deleterolehidebutton() {
      this.deletemodal.modal.hide();
    },
    deleteroleshowbutton(event) {
      this.deletemodal.roleid = event.currentTarget.getAttribute("data-id");
      this.deletemodal.rolename = event.currentTarget.getAttribute("data-name");
      this.deletemodal.modal.show();
    },
    async deleteRole() {
      this.deletemodal.buttonloader = true;
      await ApiService.delete(`role`, {
        params: {
          roleid: this.deletemodal.roleid
        }
      })
        .then(resp => {
          this.deletemodal.buttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getRoles();
              this.deleterolehidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error"
            });
          }
        })
        .catch(() => {
          this.deletemodal.buttonloader = false;
          Swal.fire({
            text: "Хэрэглэгч устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** component functions
     ***************************************
     ***************************************
     ***************************************/
    async getRoles() {
      this.loadingRoleTable = true;
      await ApiService.get(`role`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.roleTableData = results.response.map(role => ({
              roleid: role.roleid,
              rolename: role.rolename,
              desc: role.description
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingRoleTable = false;
        });
    },
    async getPermissions() {
      await ApiService.get(`permission`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.perTableData = results.response.map(per => ({
              infotypecode: per.infotypecode,
              trole: per.trole,
              infotypename: per.infotypename
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        });
    },
    async getRolePermissions(roleid) {
      this.loadingRolePerTable = true;
      await ApiService.get(`rolepermission`, `?roleid=${roleid}`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.rolePerTableData = results.response.map(per => ({
              infotypecode: per.infotypecode,
              infotypename: per.infotypename,
              trole: per.trole,
              infostat: per.infostat
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingRolePerTable = false;
        });
    }
  }
});
